/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { doc, onSnapshot, collectionGroup, FieldValue, arrayRemove, arrayUnion, updateDoc, deleteField, deleteDoc, collection, where, query, getDocs, setDoc, getDoc, serverTimestamp, } from "firebase/firestore";
import { MessageDatadb, MessageReactiondb, TypingStatusdb, db } from "../firebase";
// import { onSnapshot } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import EmojiPicker, {
    EmojiStyle,
    Emoji,
} from "emoji-picker-react";
import { BsEmojiSmile } from 'react-icons/bs';
// import { useSelector, useDispatch } from "react-redux";
import swal from '@sweetalert/with-react';
// import { ShowTools, ShowInput } from "../actions/index";
import { useLongPress } from 'use-long-press';
import { Anchorme } from 'react-anchorme'

const GroupMessage = (props) => {
    const { currentUser } = useContext(AuthContext);
    const { data } = useContext(ChatContext);
    const [loading, setLoading] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [activeClassname, setActiveClassname] = useState("");
    const [emojiSelector, setEmojiSelector] = useState(false);
    const [emojiData, setEmojiData] = useState(null);
    const [emojiReactedUserName, setEmojiReactedUserName] = useState("");
    const [emojiReactedMessageID, setEmojiReactedMessageID] = useState("");

    const ref = useRef();

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [props.Message]);

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 4000);
        }
    }, [loading]);

    const ShowMoreOptionsToolbox = async () => {
        if (showMoreOptions) {
            setShowMoreOptions(false)
            setActiveClassname("")
            setEmojiSelector(false)
        } else {
            setShowMoreOptions(true)
            setActiveClassname("ToolBoxIsActive")
        }
    }
    const CloseMoreOptionsToolbox = async () => {
        if (showMoreOptions) {
            setShowMoreOptions(false)
            setEmojiSelector(false)
        }
    }

    const ReactToMessage = async () => {
        if (emojiSelector) {
            setEmojiSelector(false)
        } else {
            setEmojiSelector(true)
        }
    }

    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            var current = new Date();
            var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
            var CurrentDate = current.toLocaleDateString();
            setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const CombinedFunction = () => {
        props.ShowOptions(true)
        // change()
        // OpenOptionsWindow()
        props.onChange(props.Message.id)
        props.MessageText(props.Message.text)
        props.MessageDoc(props.Message.document)
        props.MessageImg(props.Message.img)
        props.MessageAudio(props.Message.music)
        props.MessageVideo(props.Message.video)
        props.MessageSenderID(props.Message.senderId)
        // props.MessageReceiverID(props.Message.receiverId)
        const CombinedMessageDateTime = props.Message.MessageDate + " " + props.Message.MessageTime
        props.Message.MessageDate ? props.MessageSentTime(CombinedMessageDateTime) : props.MessageSentTime(props.Message.MessageSentTimestamp)
    }

    const bind = useLongPress(() => {
        props.onChange(props.Message.id)
        props.MessageText(props.Message.text)
        props.MessageDoc(props.Message.document)
        props.MessageImg(props.Message.img)
        props.MessageAudio(props.Message.music)
        props.MessageVideo(props.Message.video)
        props.MessageSenderID(props.Message.senderId)
        // props.MessageReceiverID(props.Message.receiverId)
        const CombinedMessageDateTime = props.Message.MessageDate + " " + props.Message.MessageTime
        props.Message.MessageDate ? props.MessageSentTime(CombinedMessageDateTime) : props.MessageSentTime(props.Message.MessageSentTimestamp)
        navigator.vibrate(40);
        props.ShowOptions(true);
    });

    const HideOptions = () => {
        if (props.ShowOptionsState === false) {
            return false
        } else {
            props.ShowOptions(false);
        }
    }

    const LikeMessage = async (val) => {
        await setDoc(doc(MessageReactiondb, "MessageReaction", val), {
            ReactedMessageID: val,
            EmojiCode: "2764-fe0f",
            NameOfUserReactedOnMessage: currentUser.displayName,
        });
    }


    const OpenImage = () => {
        swal(
            <img src={props.Message.img} alt={"IMAGE : " + props.Message.id} className="w-full h-full md:h-3/5 md:w-3/5 rounded-xl" />, { button: "CLOSE", }
        )
    }

    const StopVideoPlay = () => {
        document.getElementById("Video").pause();
        document.getElementById("Video").muted = true;
        document.getElementById("Video").onplay = document.getElementById("Video").pause();
        document.getElementById("Video").onPlay = document.getElementById("Video").pause();
    }

    const OpenVideo = () => {
        // setVideoPlayState(true)
        // document.getElementById("Video").pause();
        // document.getElementById("Video").muted = true;
        // document.getElementById("Video").pause();
        // document.getElementById("Video").muted = true;
        swal(
            <>
                <video controls className="w-3/5 md:h-3/5 md:w-3/5 flex justify-center items-center rounded-3xl" id="videoPlayer">
                    <source src={props.Message.video} type="video/mp4" />
                </video>
            </>
            , {
                button: "CLOSE",
            }
        ).then((stop) => {
            if (stop) {
                document.getElementById("videoPlayer").pause();
            }
        });
        document.getElementById("Video").pause();
        document.getElementById("Video").muted = true;
        document.getElementById("Video").pause();
        document.getElementById("Video").muted = true;
    }

    const RemoveReaction = () => {
        swal({
            title: "Remove Reaction",
            text: "Are You Sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteDoc(doc(MessageReactiondb, "MessageReaction", props.Message.id));
                setEmojiData("")
                setEmojiReactedUserName("")
                setEmojiReactedMessageID("")
                swal("Reaction Deleted", {
                    icon: "success",
                });
            }
        });
    }

    const ShowReaction = () => {
        if (emojiReactedUserName === currentUser.displayName) {
            swal(
                <>
                    <div className="flex flex-col justify-center text-center align-center items-center">
                        <h1 className="bg-blue-300 p-2 rounded-full font-extrabold w-full">
                            <Emoji unified={emojiData} emojiStyle={EmojiStyle.NATIVE} size={20} /> : {emojiReactedUserName}
                        </h1>
                    </div>
                </>, {
                buttons: ["CLOSE", "DELETE"]
            }
            ).then((Delete) => {
                if (Delete) {
                    RemoveReaction()
                }
            });
        } else {
            swal(
                <>
                    <div className="flex flex-col justify-center text-center align-center items-center">
                        <h1 className="bg-blue-300 p-2 rounded-full font-extrabold w-full">
                            <Emoji unified={emojiData} emojiStyle={EmojiStyle.NATIVE} size={20} /> : {emojiReactedUserName}
                        </h1>
                    </div>
                </>, {
                button: "CLOSE"
            }
            )
        }
    }

    return (
        <>
            <>
                {props.Message.senderId === currentUser.uid ?
                    <div id="MessageBOX" className={`LongPressDiv flex gap-2 flex-row-reverse Message ${props.Message.senderId === currentUser.uid && "Owner"} ${props.Message.id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={HideOptions}>
                        <picture className="relative flex h-8 aspect-square cursor-pointer sm:h-9 lg:h-10">
                            <img className="h-full w-full object-cover block rounded-full" src={currentUser.photoURL} alt="user" />
                        </picture>
                        <div className="w-full">
                            <div className="flex flex-col">
                                {props.Message.text === "" ? null :
                                    <>
                                        <div className="flex items-center gap-2 flex-row-reverse" id="MsgContainer">
                                            <p {...bind()}
                                                className="select_none text-white break-words text-right text-xs mt-1 py-2 px-4 bg-violet-600 rounded-2xl rounded-tr-none shadow-2xl shadow-violet-500/25 max-w-[45%] w-max md:max-w-[60%]" id={props.Message.id}>
                                                <Anchorme target="_blank" rel="noreferrer noopener" style={{ fontWeight: '900' }}>
                                                    {props.Message.text}
                                                </Anchorme>
                                            </p>
                                            <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                        </div>
                                        <h1 ref={ref} className="invisible"></h1>
                                    </>
                                }
                                {props.Message.img &&
                                    <>
                                        <aside className="flex w-full h-full md:max-w-[100%] space-x-2 justify-end flex-row items-center" id="MsgContainer">
                                            <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                            <picture {...bind()} onDoubleClick={() => LikeMessage(props.Message.id)} className="w-3/5 h-3/5 md:h-1/5 md:w-1/5 cursor-pointer">
                                                <img className="rounded-lg h-full w-full object-fit" src={props.Message.img} alt="img" onClick={OpenImage} />
                                            </picture>
                                        </aside>
                                        <h1 ref={ref} className="invisible"></h1>
                                    </>
                                }
                                {props.Message.music &&
                                    <>
                                        <aside className="flex w-full h-full space-x-2 md:max-w-[100%] justify-end flex-row items-center" id="MsgContainer">
                                            <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                            <AudioPlayer {...bind()} onDoubleClick={() => LikeMessage(props.Message.id)} src={props.Message.music} controls style={{ "border-radius": "20px" }} />
                                        </aside>
                                        <h1 ref={ref} className="invisible"></h1>
                                    </>
                                }
                                {props.Message.video &&
                                    <>
                                        <aside className="flex w-full h-full space-x-2 md:max-w-[100%] justify-end flex-row items-center" id="MsgContainer">
                                            <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                            <video {...bind()} onDoubleClick={() => LikeMessage(props.Message.id)} className="w-3/5 md:h-1/5 md:w-1/5 rounded-2xl cursor-pointer" id="Video" onClick={OpenVideo}>
                                                <source src={props.Message.video} type="video/mp4" onClick={OpenVideo} />
                                            </video>
                                        </aside>
                                        <h1 ref={ref} className="invisible"></h1>
                                    </>
                                }
                                {props.Message.document &&
                                    <>
                                        <aside className="flex w-full h-full space-x-2 md:max-w-[100%] justify-end flex-row items-center" id="MsgContainer">
                                            <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                            <iframe {...bind()} src={props.Message.document} title="Document" frameborder="0" height="300" className="w-4/5 rounded-2xl"></iframe>
                                        </aside>
                                        <h1 ref={ref} className="invisible"></h1>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {props.SelectedGroupData.Members?.map(G => (
                            props.Message.senderId === G.id ?
                                <div id="MessageBOX" className={`LongPressDiv flex gap-2 Message ${props.Message.senderId === G.id && "User"} ${props.Message.id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={HideOptions}>
                                    <textarea id="TextArea" style={{ display: 'none' }}></textarea>
                                    <picture className="relative flex h-8 aspect-square cursor-pointer sm:h-9 lg:h-10">
                                        <img className="h-full w-full object-cover block rounded-full" src={G.photoURL} alt="user" />
                                    </picture>
                                    <div className="w-full">
                                        <div>
                                            {props.Message.text === "" ? null :
                                                <>
                                                    <div className="flex items-center gap-2 flex-row" id="MsgContainer">
                                                        <p {...bind()} className="select_none text-xs break-words mt-1 py-2 px-4 bg-white rounded-2xl rounded-tl-none max-w-[45%] w-max md:max-w-[60%]" id={props.Message.id}>
                                                            <Anchorme target="_blank" rel="noreferrer noopener" style={{ fontWeight: '900' }}>
                                                                {props.Message.text}
                                                            </Anchorme>
                                                        </p>
                                                        <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                                    </div>
                                                    <h1 ref={ref} className="invisible"></h1>
                                                </>
                                            }
                                            {props.Message.img &&
                                                <>
                                                    <aside className="flex mt-2 w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                                                        <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                                        <picture {...bind()} className="w-3/5 h-3/5 cursor-pointer">
                                                            <img className="rounded-lg h-full w-full object-fit" src={props.Message.img} alt="img" />
                                                        </picture>
                                                    </aside>
                                                    <h1 ref={ref} className="invisible"></h1>
                                                </>
                                            }
                                            {props.Message.music &&
                                                <>
                                                    <aside className="flex mt-2 w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                                                        <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                                        <AudioPlayer {...bind()} src={props.Message.music} controls style={{ "border-radius": "20px" }} />
                                                    </aside>
                                                    <h1 ref={ref} className="invisible"></h1>
                                                </>
                                            }
                                            {props.Message.video &&
                                                <>
                                                    <aside className="flex mt-2 w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                                                        <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                                        <video {...bind()} controls className="w-4/5 rounded-2xl cursor-pointer" id="Video">
                                                            <source src={props.Message.video} type="video/mp4" />
                                                        </video>
                                                    </aside>
                                                    <h1 ref={ref} className="invisible"></h1>
                                                </>
                                            }
                                            {props.Message.document &&
                                                <>
                                                    <aside className="flex w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                                                        <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                                                        <iframe {...bind()} src={props.Message.document} title="Document" frameborder="0" height="300" className="w-4/5 rounded-2xl"></iframe>
                                                    </aside>
                                                    <h1 ref={ref} className="invisible"></h1>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> : null
                        ))}
                    </>
                }
            </>
        </>
    );
};

export default GroupMessage;