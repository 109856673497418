/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
// import { GroupContext } from "../context/GroupContext";
// import { SettingContext } from "../context/SettingContext";
// import { INITIAL_STATE, componentReducer } from "../context/SettingContext";
import { db, UserChatsdb, Chatsdb, MessageDatadb } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
// import LoadingBar from 'react-top-loading-bar';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import * as LoadingSpinner from "react-spinners-kit";
import { CgLogOut } from 'react-icons/cg';
// import { MdDarkMode } from 'react-icons/md';
// import { BsFillSunFill } from 'react-icons/bs';
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
// import moment from 'moment';
// import { async } from "@firebase/util";

const Chats = (props) => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [noUserChats, setNoUserChats] = useState(null);
  const [err, setErr] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [loggingOut, setLoggingOut] = useState(false);
  // const [toggleSettings, setToggleSettings] = useState(false);
  const [toggleComponents, setToggleComponents] = useState("Home");

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  // const { dispatchGroup } = useContext(GroupContext);
  // const { groupData } = useContext(GroupContext);

  const [photoUrlOfUser, setPhotoUrlOfUser] = useState(null)
  const [displayNameOfUser, setDisplayNameOfUser] = useState(null)
  // const { changeComponent } = useContext(SettingContext);

  // const [componentState, dispatchComponent] = useReducer(componentReducer, INITIAL_STATE);
  // const [componentState, dispatchComponent] = useReducer(componentReducer, INITIAL_STATE);
  // console.log(componentState.component)

  const handleSelect = async (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  // useEffect(() => {
  //   if (loading) {
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);
  //   }
  // }, [loading]);

  useEffect(() => {
    setPhotoUrlOfUser(currentUser.photoURL)
    setDisplayNameOfUser(currentUser.displayName)
  }, []);

  const handleSearch = async () => {
    const q = query(
      collection(db, "Users"),
      where("displayName", "==", username)
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUser(doc.data());
      });
    } catch (err) {
      setErr(true);
    }
  };

  const handleKey = (e) => {
    e.code === "Enter" && handleSearch();
    e.code === "Backspace" && setUser(null);
  };

  // const sleep = ms => new Promise(
  //   resolve => setTimeout(resolve, ms)
  // );

  const handleNewUsers = async (name) => {
    // setUsername(name) && handleSearch();
    setUsername(name);
    const q = query(
      collection(db, "Users"),
      where("displayName", "==", name)
    );
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUser(doc.data());
      });
    } catch (err) {
      setErr(true);
    }
  };

  const [creatingChats, setCreatingChats] = useState(false)

  const SelectSearchedUser = async (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      setCreatingChats(true)
      const res = await getDoc(doc(Chatsdb, "Chats", combinedId));

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(Chatsdb, "Chats", combinedId), { Messages: [] });

        //create user chats
        await updateDoc(doc(UserChatsdb, "UserChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
            chatId: combinedId,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(UserChatsdb, "UserChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
            chatId: combinedId,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        // await setDoc(doc(db, "MessageReaction", combinedId), {});

        // await updateDoc(doc(MessageDatadb, "MessageData", currentUser.uid), {
        //   MessageUnSeen: [],
        //   InChatWindow: "",
        // });
        // await updateDoc(doc(MessageDatadb, "MessageData", user.uid), {
        //   MessageUnSeen: [],
        //   InChatWindow: "",
        // });

        await updateDoc(doc(db, "Users", currentUser.uid), {
          NoUserChats: false,
        });
        await updateDoc(doc(db, "Users", user.uid), {
          NoUserChats: false,
        });

        setCreatingChats(false)
      }
    } catch (err) {
      setErr(true);
    }

    setUser(null);
    setUsername("")
  };

  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const HandleSignOut = async (e) => {
    // document.title = "Drake Chat • Logging Out"
    setLoggingOut(true)
    await updateDoc(doc(db, "Users", currentUser.uid), {
      Status: "Offline Since",
      LogoutTime: combinedCurrentDateTime
    });
    // setProgressPercent(50)
    signOut(auth)
    // setProgressPercent(100)
    setLoggingOut(false)
    document.title = "Drake Chat • Login"
  }
  if (loggingOut) {
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • Logging Out"
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • Logging Out"
    }
  }

  const RemoveInChatWindow = async () => {
    await updateDoc(doc(MessageDatadb, "MessageData", currentUser.uid), {
      InChatWindow: "",
    });
  }

  useEffect(() => {
    if (props.InChatWindow !== "") {
      RemoveInChatWindow()
    }
  }, [])

  const ToggleChatsGroupsSettings = (value) => {
    if (value === "Home") {
      setToggleComponents("Home")
      // document.title = "Drake Chat • Settings"
      if (props.SendMsgCount === 0) {
        document.title = "Drake Chat • Home"
      } else {
        document.title = "(" + props.SendMsgCount + ") Drake Chat • Home"
      }
    } else if (value === "Settings") {
      setToggleComponents("Settings")
      if (props.SendMsgCount === 0) {
        document.title = "Drake Chat • Settings"
      } else {
        document.title = "(" + props.SendMsgCount + ") Drake Chat • Settings"
      }
    } else if (value === "Groups") {
      setToggleComponents("Groups")
      if (props.SendMsgCount === 0) {
        document.title = "Drake Chat • Groups"
      } else {
        document.title = "(" + props.SendMsgCount + ") Drake Chat • Groups"
      }
    }
  }

  if (toggleComponents === "Groups") {
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • Groups"
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • Groups"
    }
  } else if (toggleComponents === "Home") {
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • Home"
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • Home"
    }
  } else if (toggleComponents === "Settings") {
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • Settings"
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • Settings"
    }
  }

  // const ShowLogout = () => {
  //   document.getElementById("Logout").classList.toggle("hidden");
  // }

  // const startTime = moment(user?.LogoutTime, 'DD/MM/YYYY hh:mm a');
  // const endTime = moment(combinedCurrentDateTime, 'DD/MM/YYYY hh:mm a');

  // const duration = moment.duration(endTime.diff(startTime));

  // const hours = parseInt(duration.asHours());

  // const days = parseInt(duration.asDays());

  // const minutes = parseInt(duration.asMinutes()) % 60;

  // const lastActivem = minutes + "m"
  // const lastActiveh = hours + "h"
  // const lastActived = days + "d"

  const UserSelection = () => {
    props.selectuser()
    props.FwdMsg(false)
    props.onChange("Chat")
  }
  const GroupSelection = () => {
    props.selectuser()
    props.FwdMsg(false)
    props.onChange("GROUPS")
  }
  const BackBtn = () => {
    props.onChange("Home")
    setToggleComponents("Home")
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • Home"
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • Home"
    }
  }

  // useEffect(() => {
  //   const GetUserDetailsInstantly = async () => {
  //     await onSnapshot(doc(db, "Users", currentUser.uid), (doc) => {
  //       setNoUserChats(doc.data().NoUserChats);
  //       setPhotoUrlOfUser(doc.data().photoURL)
  //       setDisplayNameOfUser(currentUser.displayName)
  //     });
  //   }
  //   GetUserDetailsInstantly()
  // }, []);

  useEffect(() => {
    if (props.UserData !== null) {
      setNoUserChats(props.UserData.NoUserChats);
      setPhotoUrlOfUser(props.UserData.photoURL)
      setDisplayNameOfUser(currentUser.displayName)
    }
  }, [props.UserData])

  const ChangeComponentToCreateGroup = () => {
    props.onChange("CRTGRP");
  }

  const handleSelectGroup = async (g) => {
    // dispatchGroup({ type: "CHANGE_GROUP", payload: g });
    props.ChangeGroup(g);
    props.onChange("GROUPS");
  };

  return (
    <>
      <nav
        className="bg-transparent backdrop-blur-2xl rounded-2xl flex flex-col justify-between w-16 p-2 h-full shadow-2xl absolute top-0 -left-16 transition duration-300 z-20 sm:w-20 sm:relative sm:left-0 sm:shadow-none"
        id="sidebar">
        <ul className="w-full flex flex-col gap-10">
          {toggleComponents === "Home" ?
            <>
              <li onClick={() => ToggleChatsGroupsSettings("Home")}
                className="bg-white dark:bg-slate-800 dark:border-white rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500">
                <i className="dark:text-white fi fi-rr-comment transition duration-300 text-violet-600"></i>
                <span className="dark:text-white text-xs font-bold text-violet-600">Chats</span>
              </li>
              <li onClick={() => ToggleChatsGroupsSettings("Groups")}
                className="bg-white dark:bg-slate-800 dark:border-white cursor-pointer rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 group hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner">
                <i className="dark:text-white fi fi-sr-users-medical transition duration-300 text-violet-600"></i>
                <span className="dark:text-white text-xs font-bold transition duration-300 text-violet-600">Groups</span>
              </li>
              <li onClick={() => ToggleChatsGroupsSettings("Settings")}
                className="bg-white dark:bg-slate-800 dark:border-white cursor-pointer rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 group hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner">
                <i className="dark:text-white fi fi-rr-settings transition duration-300 text-violet-600"></i>
                <span className="dark:text-white text-xs font-bold transition duration-300 text-violet-600">Settings</span>
              </li>
              <li onClick={HandleSignOut}
                className="bg-white dark:bg-slate-800 dark:border-white hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500">
                <i className="dark:text-white text-violet-600"><CgLogOut /></i>
                <span className="dark:text-white text-xs font-bold text-violet-600">Logout</span>
              </li>
            </>
            :
            toggleComponents === "Settings"
              ?
              <>
                <li onClick={() => ToggleChatsGroupsSettings("Home")}
                  className="bg-white dark:bg-slate-800 dark:border-white cursor-pointer rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 group hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner">
                  <i className="dark:text-white fi fi-rr-comment transition duration-300 text-violet-600"></i>
                  <span className="dark:text-white text-xs font-bold text-violet-600">Chats</span>
                </li>
                <li onClick={() => ToggleChatsGroupsSettings("Groups")}
                  className="bg-white dark:bg-slate-800 dark:border-white cursor-pointer rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 group hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner">
                  <i className="dark:text-white fi fi-sr-users-medical transition duration-300 text-violet-600"></i>
                  <span className="dark:text-white text-xs font-bold transition duration-300 text-violet-600">Groups</span>
                </li>
                <li onClick={() => ToggleChatsGroupsSettings("Settings")}
                  className="bg-white dark:bg-slate-800 dark:border-white rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500">
                  <i className="dark:text-white fi fi-rr-settings transition duration-300 text-violet-600"></i>
                  <span className="dark:text-white text-violet-600 text-xs font-bold transit:text-violet-600">Settings</span>
                </li>
                <li onClick={HandleSignOut}
                  className="bg-white dark:bg-slate-800 dark:border-white hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500">
                  <i className="dark:text-white text-violet-600"><CgLogOut /></i>
                  <span className="dark:text-white text-xs font-bold text-violet-600">Logout</span>
                </li>
              </>
              :
              toggleComponents === "Groups" &&
              <>
                <li onClick={() => ToggleChatsGroupsSettings("Home")}
                  className="bg-white dark:bg-slate-800 dark:border-white cursor-pointer rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 group hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner">
                  <i className="dark:text-white fi fi-rr-comment transition duration-300 text-violet-600"></i>
                  <span className="dark:text-white text-xs font-bold text-violet-600">Chats</span>
                </li>
                <li onClick={() => ToggleChatsGroupsSettings("Groups")}
                  className="bg-white dark:bg-slate-800 dark:border-white rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500">
                  <i className="dark:text-white fi fi-sr-users-medical transition duration-300 text-violet-600"></i>
                  <span className="dark:text-white text-xs font-bold transition duration-300 text-violet-600">Groups</span>
                </li>
                <li onClick={() => ToggleChatsGroupsSettings("Settings")}
                  className="bg-white dark:bg-slate-800 dark:border-white cursor-pointer rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 group hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner">
                  <i className="dark:text-white fi fi-rr-settings transition duration-300 text-violet-600"></i>
                  <span className="dark:text-white text-violet-600 text-xs font-bold transit:text-violet-600">Settings</span>
                </li>
                <li onClick={HandleSignOut}
                  className="bg-white dark:bg-slate-800 dark:border-white hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500">
                  <i className="dark:text-white text-violet-600"><CgLogOut /></i>
                  <span className="dark:text-white text-xs font-bold text-violet-600">Logout</span>
                </li>
              </>
          }
        </ul>
        <ul className="w-full flex-col gap-10 hidden" id="Logout">
          <li onClick={HandleSignOut}
            className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-xl aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500">
            <i className="text-violet-600"><CgLogOut /></i>
            <span className="text-xs font-bold text-violet-600">Logout BTN</span>
          </li>
        </ul>
        <picture
          className="cursor-pointer w-full rounded-full aspect-square flex justify-center items-center overflow-hidden p-1 border-violet-400 border-2 transition duration-300 hover:scale-105 active:scale-95">
          <img className="bg-gradient-to-r from-fuchsia-500 to-orange-500 rounded-full aspect-square object-cover" src={photoUrlOfUser} alt="avatar" />
        </picture>
      </nav>
      {toggleComponents === "Home" ?
        <section className="bg-transparent overflow-y-auto backdrop-blur-2xl rounded-2xl p-2 w-full h-full flex flex-col gap-2 lg:w-2/5 xl:w-1/4">
          <div className="flex gap-2 w-full h-[5%]">
            <label className="relative dark:bg-slate-800 bg-violet-50 rounded-lg overflow-hidden h-8 w-full" for="search">
              <i onClick={handleSearch} className="absolute cursor-pointer right-1 shadow-lg shadow-violet-400/50.5 flex h-full items-center fi fi-rr-search dark:text-slate-200"></i>
              <input className="w-11/12 h-full bg-transparent dark:bg-slate-800 dark:text-white dark:focus:text-white font-bold text-sm pl-2 outline-0 focus:text-violet-600" type="text" name="search" id="search" placeholder="Search Users" onKeyDown={handleKey}
                onChange={(e) => setUsername(e.target.value)}
                value={username} />
            </label>
          </div>
          {err && <span className="text-red-600 font-extrabold text-uppercase">User Not Found!</span>}
          {user && (
            <>
              <div className="h-[80%]">
                <ul className="mt-2 flex flex-col gap-1 overflow-y-scroll h-[90%]" id="messages-list">
                  <div onClick={SelectSearchedUser}>
                    <li
                      className="flex relative items-center gap-2 cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                      <picture className="relative flex h-12 aspect-square">
                        <img className="h-full w-full object-cover block rounded-full" src={user.photoURL} alt="user" />
                        {user.Status === "Active Now" ? <b className="bg-lime-400 h-3.5 border-2 border-white shadow-lg shadow-pink-500 aspect-square block rounded-full absolute right-0 bottom-0"></b> : <b className="bg-red-400 h-3.5 border-2 border-white shadow-lg shadow-pink-500 aspect-square block rounded-full absolute right-0 bottom-0"></b>}
                      </picture>
                      <div className="">
                        <header className="text-sm font-bold dark:text-white">{user.displayName}</header>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </>
          )}
          {noUserChats ? creatingChats ?
            <div className="h-[80%]">
              <h2 className="font-extrabold text-2xl dark:text-white text-slate-800 uppercase mt-2 justify-center text-center underline">Creating Chats</h2>
            </div>
            :
            <div className="h-[80%] p-4 gap-4">
              <h2 className="font-extrabold text-2xl dark:text-white text-slate-800 uppercase mt-2 justify-center text-center underline">WELCOME TO DRAKE CHAT</h2>
              {/* <h3 className="font-extrabold text-lg dark:text-white text-slate-800 uppercase mt-2 justify-center text-center">Search Any Of The Below Users To Start Chatting</h3> */}
              <h3 className="font-extrabold text-lg dark:text-white text-slate-800 uppercase mt-4 justify-center text-center">Search Users Using Their UserNames</h3>
              <h3 className="font-extrabold text-lg dark:text-white text-slate-800 uppercase mt-6 justify-center text-justify">Chat with your loved ones for free and enjoy the peace of mind that comes with 256-bit encryption. This chat app protects your privacy and keeps your conversations safe from prying eyes.</h3>
              {/* <ul className="mt-4 flex flex-col gap-4 overflow-y-scroll overflow-x-hidden h-[70%]" id="messages-list">
                {!props.IsLoading &&
                  <>
                    <div className="space-y-4">
                      <li onClick={() => handleNewUsers("Drake Parker")}
                        className="flex relative items-center gap-2 cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                        <picture className="relative flex h-12 aspect-square">
                          <img className="h-full w-full object-cover block rounded-full" src="https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/UsersProfilePicture%2FDrake%20Parker?alt=media&token=5952649a-f77e-4ac4-9062-7ab99ffb35f2" alt="user" />
                        </picture>
                        <div className="">
                          <header className="text-sm font-bold dark:text-white">Drake Parker</header>
                        </div>
                      </li>
                    </div>
                    <div className="space-y-4">
                      <li onClick={() => handleNewUsers("Vipul")}
                        className="flex relative items-center gap-2 cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                        <picture className="relative flex h-12 aspect-square">
                          <img className="h-full w-full object-cover block rounded-full" src="https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/UsersProfilePicture%2FVipul?alt=media&token=09f020b0-fce5-4529-aaa4-f6d848152510" alt="user" />
                        </picture>
                        <div className="">
                          <header className="text-sm font-bold dark:text-white">Vipul</header>
                        </div>
                      </li>
                    </div>
                    {Object.entries(props.AllUsersData)?.sort((a, b) => a[1].displayName.localeCompare(b[1].displayName)).map((user) => (
                      user[1].displayName === currentUser.displayName ? null :
                        <>
                          {user[1].displayName === "Drake Parker" || user[1].displayName === "Vipul" ? null :
                            <div className="space-y-4">
                              <li onClick={() => handleNewUsers(user[1].displayName)}
                                className="flex relative items-center gap-2 cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                                <picture className="relative flex h-12 aspect-square">
                                  <img className="h-full w-full object-cover block rounded-full" src={user[1].photoURL} alt="user" />
                                </picture>
                                <div className="">
                                  <header className="text-sm font-bold dark:text-white">{user[1].displayName}</header>
                                </div>
                              </li>
                            </div>
                          }
                        </>
                    ))}
                  </>
                }
              </ul> */}
            </div>
            :
            <div className="h-[80%]">
              <h3 className="font-extrabold text-xl dark:text-white uppercase mt-2 justify-center text-center">Inbox</h3>
              <ul className="mt-4 flex flex-col gap-4 overflow-y-scroll overflow-x-hidden h-[90%]" id="messages-list">
                {props.IsLoading &&
                  <>
                    <header className="text-violet-500 text-xl font-extrabold dark:text-purple-500">LOADING CHATS</header><LoadingSpinner.StageSpinner size={60} color="#ff0058" loading={props.IsLoading} />
                  </>
                }
                {!props.IsLoading &&
                  <>
                    {Object.entries(props.chatdata)?.sort((a, b) => b[1].date - a[1].date).map((chat) => (
                      <>
                        <div key={chat[0]} onClick={() => handleSelect(chat[1].userInfo)}>
                          <li onClick={UserSelection} id="SelectUser"
                            className="flex relative items-center gap-2 overflow-x-hidden cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                            <picture className="relative flex h-12 aspect-square">
                              <img className="h-full w-full object-cover block rounded-full" src={chat[1].userInfo.photoURL} alt="user" />
                            </picture>
                            <div className="">
                              <header className="text-sm font-bold dark:text-white">{chat[1].userInfo.displayName}</header>
                              <div className="text-sm flex w-full gap-2">
                                {props.MessageUnSeen?.map(MU => (
                                  MU === chat[1].userInfo.uid ?
                                    <p className="text-sm text-gray-300 font-extrabold uppercase">Sent You A Message</p>
                                    : null
                                ))}
                                {/* {!props.MessageData ? <p className="max-w-[80%] break-all overflow-hidden whitespace-nowrap text-ellipsis dark:text-slate-400">{chat[1].lastMessage?.text === "" ? "No Messages" : chat[1].lastMessage?.id === currentUser.uid ? "You : " + chat[1].lastMessage?.text : chat[1].lastMessage?.user + " : " + chat[1].lastMessage?.text}</p> : <p className="max-w-[80%] break-all overflow-hidden whitespace-nowrap text-ellipsis dark:text-green-300">{chat[1].lastMessage?.text === "" ? "No Messages" : chat[1].lastMessage?.id === currentUser.uid ? "• You : " + chat[1].lastMessage?.text : "• " + chat[1].lastMessage?.user + " : " + chat[1].lastMessage?.text}</p>} */}
                                {/* {props.MessageData.seen === [] ? alert("EMPTY") : console.log(props.MessageData)} */}
                                {/* {chat[1].lastMessage?.text === "" ? null : chat[1].lastMessage?.seen === "Seen" ? null : chat[1].lastMessage.UnreadMsgCount === 0 ? null : <span className="text-white bg-violet-600 font-extrabold text-sm whitespace-nowrap" style={{ "border-radius": "50%", "padding-left": "6px", "padding-right": "6px" }}>{chat[1].lastMessage.UnreadMsgCount}</span>} */}
                                {/* <p className="max-w-[80%] break-all overflow-hidden whitespace-nowrap text-ellipsis dark:text-slate-400">{chat[1].lastMessage?.text === "" ? "No Messages" : chat[1].lastMessage?.id === currentUser.uid ? "You : " + chat[1].lastMessage?.text : chat[1].lastMessage?.user + " : " + chat[1].lastMessage?.text}</p> */}
                                {/* <p className="max-w-[80%] break-all overflow-hidden whitespace-nowrap text-ellipsis dark:text-slate-400">{chat[1].lastMessage?.text === "" ? "No Messages" : chat[1].lastMessage?.id === currentUser.uid ? "You : " + chat[1].lastMessage?.text : chat[1].lastMessage?.user + " : " + chat[1].lastMessage?.text}</p> */}
                              </div>
                            </div>
                          </li>
                        </div>
                      </>
                    ))}
                  </>
                }
              </ul>
            </div>
          }
          {/* <nav
            className="bg-white flex flex-col justify-between w-16 p-2 h-full"
            id="sidebar"> */}
          <ul className="flex flex-row gap-1 w-16 p-2 h-26 space-x-8" id="Logout">
            {toggleComponents === "Home" ?
              <li onClick={() => ToggleChatsGroupsSettings("Settings")}
                className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 dark:border-white sm:hidden h-20 w-20">
                <i className="fi fi-rr-settings transition dark:text-white duration-300 text-violet-600 group-hover:text-violet-800"></i>
                <span className="text-xs font-bold dark:text-white text-violet-600">Settings</span>
              </li>
              :

              <li onClick={() => ToggleChatsGroupsSettings("Home")}
                className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 dark:border-white sm:hidden h-20 w-20">
                <i className="fi fi-rr-comment transition dark:text-white duration-300 text-violet-600 group-hover:text-violet-800"></i>
                <span className="text-xs font-bold dark:text-white text-violet-600">Chats</span>
              </li>
            }
            <li onClick={() => ToggleChatsGroupsSettings("Groups")}
              className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 dark:border-white sm:hidden h-20 w-20">
              <i className="fi fi fi-sr-users-medical transition dark:text-white duration-300 text-violet-600 group-hover:text-violet-800"></i>
              <span className="text-xs font-bold dark:text-white text-violet-600">Groups</span>
            </li>
            <li onClick={HandleSignOut}
              className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 dark:border-white sm:hidden h-20 w-20">
              <i className="text-violet-600 dark:text-white"><CgLogOut /></i>
              <span className="text-xs font-bold dark:text-white text-violet-600">{loggingOut === true ? "Logging Out" : "Logout"}</span>
            </li>
          </ul>
          {/* </nav> */}
        </section>
        :
        toggleComponents === "Settings" ?
          <>
            <section
              className="absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl flex flex-col gap-2 w-full h-full top-0 right-0 p-4 shadow-xl md:max-w-[320px] xl:shadow-none xl:block xl:relative xl:w-1/4 overflow-x-hidden"
              id="detail">

              <header className="flex flex-col items-center gap-2 w-full h-[30%] relative">
                <picture
                  className="bg-gradient-to-r from-fuchsia-500 to-orange-500 rounded-full relative flex h-24 aspect-square p-0.5 shadow-2xl shadow-violet-500/50">
                  <img className="h-full w-full object-cover block rounded-full border-8 border-white" src={photoUrlOfUser} alt="user" />
                </picture>
                <div className="flex flex-col items-center">
                  <h2 className="font-extrabold text-lg dark:text-white">{displayNameOfUser}</h2>
                </div>
              </header>

              <div className="w-full flex flex-col gap-2 h-[100%] overflow-hidden">
                <header className="w-full flex justify-center text-center align-center">
                  <h4 className="font-extrabold text-base dark:text-white text-center justify-center align-center">CHANGE SETTINGS</h4>
                </header>
                <div className="overflow-y-scroll h-[100%]">
                  <ul className="grid grid-cols-2 md:grid-cols-2 gap-10 p-4">
                    <li onClick={() => props.onChange("BG")}
                      className="relative flex overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                      <img className="h-full w-full object-cover block" src="https://images.unsplash.com/photo-1545569341-9eb8b30979d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8amFwYW58ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" alt="user" />
                      <div
                        className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                        <h1 className="font-extrabold text-red-500">Change Background</h1>
                      </div>
                    </li>
                    <li onClick={() => props.onChange("PFP")}
                      className="relative flex overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                      <img className="h-full w-full object-cover block" src="https://img.freepik.com/free-vector/hand-drawn-different-people-icons-pack_23-2149086450.jpg?w=740&t=st=1671978046~exp=1671978646~hmac=03686362d05f35b33dc3d81bd8f8c7e854026edda254e0a89fc6f3ce009131e0" alt="user" />
                      <div
                        className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                        <h1 className="font-extrabold text-red-500">Change Profile Pic</h1>
                      </div>
                    </li>
                    <li onClick={() => props.onChange("PWD")}
                      className="relative flex overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                      <img className="h-full w-full object-cover block" src="https://img.freepik.com/free-vector/secure-login-concept-illustration_114360-4685.jpg?w=740&t=st=1673560854~exp=1673561454~hmac=7753bfd06758d4f647ecb4bd754a29faccd08091e90004c9463fb469b86a0736" alt="user" />
                      <div
                        className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                        <h1 className="font-extrabold text-red-500">Change Password</h1>
                      </div>
                    </li>
                    <li onClick={() => props.onChange("EID")}
                      className="relative flex overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                      <img className="h-full w-full object-cover block" src="https://img.freepik.com/free-vector/email-campaign-concept-illustration_114360-1681.jpg?w=740&t=st=1673560601~exp=1673561201~hmac=88f2346885bb45dd14e0e7adb7389637142fd4b6a28d1987d10fc6310cdf3f42" alt="user" />
                      <div
                        className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                        <h1 className="font-extrabold text-red-500">Change E-Mail ID</h1>
                      </div>
                    </li>
                    <li onClick={() => props.onChange("USR")}
                      className="relative flex overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                      <img className="h-full w-full object-cover block" src="https://img.freepik.com/free-vector/user-verification-unauthorized-access-prevention-private-account-authentication-cyber-security-people-entering-login-password-safety-measures_335657-8.jpg?w=740&t=st=1673560840~exp=1673561440~hmac=19b61b88778de2fb2bb80cfb50c37575f50e98ba6648035c66024cb3c86b79c6" alt="user" />
                      <div
                        className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                        <h1 className="font-extrabold text-red-500">Change Username</h1>
                      </div>
                    </li>
                    <li onClick={() => props.onChange("DLT")}
                      className="relative flex overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                      <img className="h-full w-full object-cover block" src="https://img.freepik.com/premium-vector/people-cleaning-mobile-phone-from-trash-files-man-woman-deleting-documents-with-software-user-removing-folder-with-document-mail-spam-waste-bin-cleansing-cache_458444-1402.jpg?w=826" alt="user" />
                      <div
                        className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                        <h1 className="font-extrabold text-red-500">Delete Account</h1>
                      </div>
                    </li>
                    {/* <li onClick={() => props.onChange("USR")}
                    className="relative flex relative overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                    <img className="h-full w-full object-cover block" src="https://img.freepik.com/free-vector/collection-usernames-different-social-media-platforms_79603-1483.jpg?w=740&t=st=1672611181~exp=1672611781~hmac=8bf5e738edc616e54541748a3da96074ce8f6ac81ce8f7d3e6df20cc8379a454" alt="user" />
                    <div
                      className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                      <h1 className="font-extrabold text-red-500">Change Username</h1>
                    </div>
                  </li>
                  <li onClick={() => props.onChange("EMI")}
                    className="relative flex relative overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                    <img className="h-full w-full object-cover block" src="https://img.freepik.com/free-vector/email-marketing-internet-chatting-24-hours-support_335657-3009.jpg?w=740&t=st=1672611307~exp=1672611907~hmac=cf0258d9fe2af118e04ea85b9e67c12cad6781cec13f4a9c004463dbacc85a4a" alt="user" />
                    <div
                      className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                      <h1 className="font-extrabold text-red-500">Change E-Mail ID</h1>
                    </div>
                  </li> */}
                    {/* {componentState.component !== 'Chat' ? */}
                    <li onClick={BackBtn}
                      className="relative flex overflow-hidden rounded-lg cursor-pointer group h-[100px] xl:aspect-square">
                      <img className="h-full w-full object-cover block" src="https://cdn-icons-png.flaticon.com/512/1251/1251806.png?w=740&t=st=1672254304~exp=1672254904~hmac=dfd9cdcaaeea68227f14d86d95372c6905decf02a06f1481c9665bbf76788413" alt="user" />
                      <div
                        className="lg:opacity-0 opacity-80 absolute flex rounded-2xl items-center text-center justify-center w-full h-full backdrop-blur-xl bg-black/1 transition duration-300 group-hover:opacity-100">
                        <h1 className="font-extrabold text-red-500">Back</h1>
                      </div>
                    </li>
                    {/* :
                    null
                  } */}
                  </ul>
                </div>
              </div>
              <div className="w-full flex flex-col gap-2 h-[40%] md:h-0">
                <div className="h-[100%] overflow-hidden">
                  <ul className="flex flex-row gap-1 w-16 p-4 h-26 space-x-8 mt-10" id="Logout">
                    {toggleComponents === "Home" ?
                      <li onClick={() => ToggleChatsGroupsSettings("Settings")}
                        className="mt-8 bg-white dark:bg-slate-800 dark:border-white hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 sm:hidden h-20 w-20">
                        <i className="dark:text-white fi fi-rr-settings transition duration-300 text-violet-600"></i>
                        <span className="dark:text-white text-xs font-bold text-violet-600">Settings</span>
                      </li>
                      :

                      <li onClick={() => ToggleChatsGroupsSettings("Home")}
                        className="mt-8 bg-white dark:bg-slate-800 dark:border-white hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 sm:hidden h-20 w-20">
                        <i className="dark:text-white fi fi-rr-comment transition duration-300 text-violet-600"></i>
                        <span className="dark:text-white text-xs font-bold text-violet-600">Chats</span>
                      </li>
                    }
                    <li onClick={() => ToggleChatsGroupsSettings("Groups")}
                      className="mt-8 bg-white dark:bg-slate-800 dark:border-white hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 sm:hidden h-20 w-20">
                      <i className="dark:text-white fi fi-sr-users-medical transition duration-300 text-violet-600"></i>
                      <span className="dark:text-white text-xs font-bold text-violet-600">Groups</span>
                    </li>
                    <li onClick={HandleSignOut}
                      className="mt-8 bg-white dark:bg-slate-800 dark:border-white hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 sm:hidden h-20 w-20">
                      <i className="dark:text-white text-violet-600"><CgLogOut /></i>
                      <span className="dark:text-white text-xs font-bold text-violet-600">{loggingOut === true ? "Logging Out" : "Logout"}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </>
          :
          toggleComponents === "Groups" &&
          <section className="bg-transparent overflow-y-auto backdrop-blur-2xl rounded-2xl p-2 w-full h-full flex flex-col gap-2 lg:w-2/5 xl:w-1/4">
            {/* <div className="flex gap-2 w-full h-[5%]">
              <label className="relative dark:bg-slate-800 bg-violet-50 rounded-lg overflow-hidden h-8 w-full" for="search">
                <i onClick={handleSearch} className="absolute cursor-pointer right-1 shadow-lg shadow-violet-400/50.5 flex h-full items-center fi fi-rr-search dark:text-slate-200"></i>
                <input className="w-11/12 h-full bg-transparent dark:bg-slate-800 dark:text-white dark:focus:text-white font-bold text-sm pl-2 outline-0 focus:text-violet-600" type="text" name="search" id="search" placeholder="Search Groups" onKeyDown={handleKey}
                  onChange={(e) => setUsername(e.target.value)}
                  value={username} />
              </label>
            </div>
            {user && (
              <>
                <div className="h-[80%]">
                  <ul className="mt-2 flex flex-col gap-1 overflow-y-scroll h-[90%]" id="messages-list">
                    <div onClick={SelectSearchedUser}>
                      <li
                        className="flex relative items-center gap-2 cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                        <picture className="relative flex h-12 aspect-square">
                          <img className="h-full w-full object-cover block rounded-full" src={user.photoURL} alt="user" />
                          {user.Status === "Active Now" ? <b className="bg-lime-400 h-3.5 border-2 border-white shadow-lg shadow-pink-500 aspect-square block rounded-full absolute right-0 bottom-0"></b> : <b className="bg-red-400 h-3.5 border-2 border-white shadow-lg shadow-pink-500 aspect-square block rounded-full absolute right-0 bottom-0"></b>}
                        </picture>
                        <div className="">
                          <header className="text-sm font-bold dark:text-white">{user.displayName}</header>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </>
            )} */}
            <div className="h-[80%]">
              <h3 className="font-extrabold text-xl dark:text-white uppercase mt-2 justify-center text-center">Groups</h3>
              <h1 className="v-screen flex items-center text-center justify-center bg-white dark:bg-slate-800 rounded-full text-slate-800 dark:text-white mt-4" onClick={ChangeComponentToCreateGroup}><button className="v-screen flex items-center text-center justify-center p-2 text-2xl">CREATE GROUP <i className="fi fi-sr-add ml-2"></i></button></h1>
              <ul className="mt-8 flex flex-col gap-4 overflow-y-scroll overflow-x-hidden h-[90%]" id="messages-list">
                {props.IsLoading &&
                  <>
                    <header className="text-violet-500 text-xl font-extrabold dark:text-purple-500 mt-8">LOADING GROUPS</header><LoadingSpinner.StageSpinner size={60} color="#ff0058" loading={props.IsLoading} />
                  </>
                }
                {!props.IsLoading &&
                  <>
                    {Object.entries(props.GroupsData)?.sort((a, b) => b[1].date - a[1].date).map((group) => (
                      <>
                        <div key={group[0]} onClick={() => handleSelectGroup(group[1])}>
                          <li id="SelectUser" onClick={() => GroupSelection()}
                            className="flex relative items-center gap-2 overflow-x-hidden cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                            <picture className="relative flex h-12 aspect-square">
                              <img className="h-full w-full object-cover block rounded-full" src={group[1].displayPic} alt="user" />
                            </picture>
                            <div className="">
                              <header className="text-sm font-bold dark:text-white">{group[1].Name}</header>
                            </div>
                          </li>
                        </div>
                      </>
                    ))}
                  </>
                }
              </ul>
            </div>
            <ul className="flex flex-row gap-1 w-16 p-2 h-26 space-x-8" id="Logout">
              <li onClick={() => ToggleChatsGroupsSettings("Home")}
                className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 dark:border-white sm:hidden h-20 w-20">
                <i className="fi fi-rr-comment transition dark:text-white duration-300 text-violet-600 group-hover:text-violet-800"></i>
                <span className="text-xs font-bold dark:text-white text-violet-600">Chats</span>
              </li>
              <li onClick={() => ToggleChatsGroupsSettings("Groups")}
                className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 dark:border-white sm:hidden h-20 w-20">
                <i className="fi fi fi-sr-users-medical transition dark:text-white duration-300 text-violet-600 group-hover:text-violet-800"></i>
                <span className="text-xs font-bold dark:text-white text-violet-600">Groups</span>
              </li>
              <li onClick={HandleSignOut}
                className="bg-white dark:bg-slate-800 hover:shadow-2xl hover:shadow-violet-500/50 hover:relative active:shadow-inner cursor-pointer rounded-lg aspect-square flex flex-col justify-center items-center transition duration-300 border-2 border-violet-500 dark:border-white sm:hidden h-20 w-20">
                <i className="text-violet-600 dark:text-white"><CgLogOut /></i>
                <span className="text-xs font-bold dark:text-white text-violet-600">{loggingOut === true ? "Logging Out" : "Logout"}</span>
              </li>
            </ul>
          </section>
      }
    </>
  )
}

export default Chats