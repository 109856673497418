/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
// import Img from "../Images/gallery.png";
import Attach from "../Images/attach.png";
// import Music from "../Images/music.png";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import {
  arrayUnion,
  doc,
  // serverTimestamp,
  // Timestamp,
  updateDoc,
  increment,
  setDoc,
  serverTimestamp,
  arrayRemove,
  getDoc,
  // getDoc,
} from "firebase/firestore";
import { Chatsdb, Chatsstorage, MessageDatadb, MessageReactiondb } from "../firebase";
import { v4 as uuid } from "uuid";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import LoadingBar from 'react-top-loading-bar';
// import { MdCancel } from 'react-icons/md'
// import swal from 'sweetalert';
import swal from '@sweetalert/with-react';
// import swal from 'react-bootstrap-sweetalert';
import EmojiPicker, {
  // EmojiStyle,
  // SkinTones,
  // Theme,
  // Categories,
  // EmojiClickData,
  // Emoji,
  // SuggestionMode,
  // SkinTonePickerLocation
} from "emoji-picker-react";
// import { BsEmojiSmile } from 'react-icons/bs';
// import { FieldValue } from 'firebase-admin/firestore'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import CryptoJS from "crypto-js";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const Input = ({ component, update, forward, forwardingContent, MessageID, MessageText, MessageDocument, MessageImage, MessageAudio, MessageVideo, MessageTime, MessageSenderID, MessageReceiverID, ProgressState, ProgressPercentage, ActionToBePerformed, HideOptions, ChangeActionToBePerformedToNull, MessageUnSeen, OtherUserMsgUnSeen, OtherUserMsgChatWindow, TypingStatus, ScrollIntoView }) => {
  const [text, setText] = useState("");
  // const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const [music, setMusic] = useState(null);
  const [video, setVideo] = useState(null);
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progresspercent, setProgressPercent] = useState();
  const [err, setErr] = useState(false);
  // const [encrptedData, setEncrptedData] = useState("");

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)
  // const [currentTime, setCurrentTime] = useState(null)
  // const [currentDate, setCurrentDate] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      // setCurrentTime(CurrentTime)
      // setCurrentDate(CurrentDate)
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // const secretPass = "QW123ERTYUIOP456XkhZG4DPIVPAHZXC789VBNMLYROC12T0fW2t2WASDFGHJKL";

  // const encryptData = () => {
  //   const data = CryptoJS.AES.encrypt(
  //     JSON.stringify(text),
  //     secretPass
  //   ).toString();

  //   setEncrptedData(data);
  //   // console.log(encrptedData)
  // };

  // useEffect(() => {
  //   encryptData()
  // }, [text])

  const InputRef = useRef()

  const HandleSend = async () => {
    InputRef.current?.focus()
    if (text === "") {
      return false
    } else {
      if (img) {
        setLoading(true);
        ProgressState(true);
        const storageRef = ref(Chatsstorage, "Images/" + uuid());

        const uploadTask = uploadBytesResumable(storageRef, img);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const roundedprogress = Math.round(progress);
            setProgressPercent(roundedprogress);
            ProgressPercentage(roundedprogress);
          },
          (error) => {
            setErr(true);
            setLoading(false);
            ProgressState(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
              await updateDoc(doc(Chatsdb, "Chats", data.chatId), {
                Messages: arrayUnion({
                  id: uuid(),
                  senderId: currentUser.uid,
                  receiverId: data.user.uid,
                  img: downloadURL,
                  MessageSentTimestamp: combinedCurrentDateTime,
                  text: ""
                }),
              });
            });
          }
        );
        setLoading(false);
      } else if (music) {
        setLoading(true);
        ProgressState(true);
        const storageRef = ref(Chatsstorage, "Music/" + uuid());

        const uploadTask = uploadBytesResumable(storageRef, music);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const roundedprogress = Math.round(progress);
            setProgressPercent(roundedprogress);
            ProgressPercentage(roundedprogress);
          },
          (error) => {
            setErr(true);
            setLoading(false);
            ProgressState(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL1) => {
              await updateDoc(doc(Chatsdb, "Chats", data.chatId), {
                Messages: arrayUnion({
                  id: uuid(),
                  senderId: currentUser.uid,
                  receiverId: data.user.uid,
                  music: downloadURL1,
                  MessageSentTimestamp: combinedCurrentDateTime,
                  text: "",
                }),
              });
            });
          }
        );
        setLoading(false);
      } else if (video) {
        setLoading(true);
        ProgressState(true);
        const storageRef = ref(Chatsstorage, "Video/" + uuid());

        const uploadTask = uploadBytesResumable(storageRef, video);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const roundedprogress = Math.round(progress);
            setProgressPercent(roundedprogress);
            ProgressPercentage(roundedprogress);
          },
          (error) => {
            setErr(true);
            setLoading(false);
            ProgressState(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL2) => {
              await updateDoc(doc(Chatsdb, "Chats", data.chatId), {
                Messages: arrayUnion({
                  id: uuid(),
                  senderId: currentUser.uid,
                  receiverId: data.user.uid,
                  video: downloadURL2,
                  MessageSentTimestamp: combinedCurrentDateTime,
                  text: "",
                }),
              });
            });
          }
        );
        setLoading(false);
      } else if (document) {
        setLoading(true);
        ProgressState(true);
        const storageRef = ref(Chatsstorage, "Documents/" + uuid());

        const uploadTask = uploadBytesResumable(storageRef, document);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const roundedprogress = Math.round(progress);
            setProgressPercent(roundedprogress);
            ProgressPercentage(roundedprogress);
          },
          (error) => {
            setErr(true);
            setLoading(false);
            ProgressState(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL3) => {
              await updateDoc(doc(Chatsdb, "Chats", data.chatId), {
                Messages: arrayUnion({
                  id: uuid(),
                  senderId: currentUser.uid,
                  receiverId: data.user.uid,
                  document: downloadURL3,
                  MessageSentTimestamp: combinedCurrentDateTime,
                  text: "",
                }),
              });
            });
          }
        );
        setLoading(false);
      }
      else {
        if (text) {
          await updateDoc(doc(Chatsdb, "Chats", data.chatId), {
            Messages: arrayUnion({
              id: uuid(),
              text,
              senderId: currentUser.uid,
              receiverId: data.user.uid,
              MessageSentTimestamp: combinedCurrentDateTime,
            }),
          });
        }
      }

      if (OtherUserMsgChatWindow !== data.chatId) {
        if (OtherUserMsgUnSeen.includes(currentUser.uid) === false) {
          await updateDoc(doc(MessageDatadb, "MessageData", data.user.uid), {
            MessageUnSeen: arrayUnion(currentUser.uid),
          });
        }
      }

      // const res = await getDoc(doc(Chatsdb, "MessageData", data.user.uid));

      // if (!res.exists()) {
      //   await setDoc(doc(Chatsdb, "MessageData", data.user.uid), {
      //     [data.chatId]: {
      //       seen: false,
      //       UnreadMessageCount: increment(1),
      //     }
      //   });
      // } else {
      // await updateDoc(doc(Chatsdb, "MessageData", data.user.uid), {
      //   [data.chatId + ".seen"]: false,
      //   [data.chatId + ".UnreadMessageCount"]: increment(1),
      // });
      // }

      // await updateDoc(doc(Chatsdb, "UserChats", currentUser.uid), {
      //   [data.chatId + ".date"]: serverTimestamp(),
      // });

      // await updateDoc(doc(Chatsdb, "UserChats", data.user.uid), {
      //   [data.chatId + ".date"]: serverTimestamp(),
      // });

      setText("");
      setImg(null);
      setMusic(null);
      setVideo(null);
      setDocument(null);
      setLoading(false);
    }
  };

  // console.log(OtherUserMsgChatWindow)

  const handleImageChange = e => {
    InputRef.current?.focus()
    // setFile(e.target.files[0])
    setText("File => " + e.target.files[0].name)
    setInputText("File => " + e.target.files[0].name)
    // console.log(e.target.files[0].size)
    if (e.target.files[0].size > 10 * 1024 * 1024) {
      swal("File Size Should Be Less Than 10 MB")
      setText("");
      setInputText("");
      setImg(null);
      setMusic(null);
      setVideo(null);
      setDocument(null);
      setLoading(false);
      return false
    }
    if (e.target.files[0].type === "image/jpeg") {
      setImg(e.target.files[0])
    } else if (e.target.files[0].type === "image/jpg") {
      setImg(e.target.files[0])
    } else if (e.target.files[0].type === "image/png") {
      setImg(e.target.files[0])
    } else if (e.target.files[0].type === "image/gif") {
      setImg(e.target.files[0])
    } else if (e.target.files[0].type === "audio/mpeg") {
      setMusic(e.target.files[0])
    } else if (e.target.files[0].type === "audio/aac") {
      setMusic(e.target.files[0])
    } else if (e.target.files[0].type === "audio/wav") {
      setMusic(e.target.files[0])
    } else if (e.target.files[0].type === "audio/ogg") {
      setMusic(e.target.files[0])
    } else if (e.target.files[0].type === "video/mp4") {
      setVideo(e.target.files[0])
    } else if (e.target.files[0].type === "video/mkv") {
      setVideo(e.target.files[0])
    } else if (e.target.files[0].type === "application/pdf") {
      setDocument(e.target.files[0])
    } else if (e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      swal("File Format Not Supported")
      setText("");
      setInputText("");
      setImg(null);
      setMusic(null);
      setVideo(null);
      setDocument(null);
      setLoading(false);
    } else if (e.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      swal("File Format Not Supported")
      setText("");
      setInputText("");
      setImg(null);
      setMusic(null);
      setVideo(null);
      setDocument(null);
      setLoading(false);
    } else if (e.target.files[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      swal("File Format Not Supported")
      setText("");
      setInputText("");
      setImg(null);
      setMusic(null);
      setVideo(null);
      setDocument(null);
      setLoading(false);
    } else {
      swal("File Format Not Supported")
      setText("");
      setInputText("");
      setImg(null);
      setMusic(null);
      setVideo(null);
      setDocument(null);
      setLoading(false);
    }
  }

  const HandleCancel = async (e) => {
    // TypingStatus(false);
    setInputText("");
    setText("");
    setImg(null);
    setMusic(null);
    setVideo(null);
    setDocument(null);
    setLoading(false);
  }

  const letters = /^\s/

  const handleKey = async (e) => {
    e.code === "Enter" && e.preventDefault();
    if (letters.test(inputText) !== true) {
      e.code === "Enter" && HandleSend();
      e.code === "Enter" && setInputText("");
      e.code === "Enter" && setText("");
      e.code === "Enter" && ScrollIntoView();
      // e.code === "Enter" && document.getElementById("message").focus();
    }
  };

  const manageSendData = async (e) => {
    setInputText("");
    HandleSend() && setText("");
    ScrollIntoView()
    // document.getElementById("message").focus();
  };

  const openDocument = async () => {
    window.open(MessageDocument, '_blank');
  };
  const openImage = async () => {
    window.open(MessageImage, '_blank');
  };
  const openAudio = async () => {
    window.open(MessageAudio, '_blank');
  };
  const openVideo = async () => {
    window.open(MessageVideo, '_blank');
  };

  const UnsendMessage = async () => {
    swal({
      title: "Are You Sure?",
      text: "Once Deleted, It Can't Be Undone!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Message Deleted", {
          icon: "success",
        });
        if (MessageText) {
          updateDoc(doc(Chatsdb, "Chats", data.chatId), {
            Messages: arrayRemove({
              id: MessageID,
              senderId: MessageSenderID,
              receiverId: MessageReceiverID,
              MessageSentTimestamp: MessageTime,
              text: MessageText,
            }),
          });
        } else if (MessageAudio) {
          updateDoc(doc(Chatsdb, "Chats", data.chatId), {
            Messages: arrayRemove({
              id: MessageID,
              senderId: MessageSenderID,
              receiverId: MessageReceiverID,
              MessageSentTimestamp: MessageTime,
              text: "",
              music: MessageAudio,
            }),
          });
          const desertRef = ref(Chatsstorage, MessageAudio);
          deleteObject(desertRef)
        } else if (MessageVideo) {
          updateDoc(doc(Chatsdb, "Chats", data.chatId), {
            Messages: arrayRemove({
              id: MessageID,
              senderId: MessageSenderID,
              receiverId: MessageReceiverID,
              MessageSentTimestamp: MessageTime,
              text: "",
              video: MessageVideo,
            }),
          });
          const desertRef = ref(Chatsstorage, MessageVideo);
          deleteObject(desertRef)
        } else if (MessageDocument) {
          updateDoc(doc(Chatsdb, "Chats", data.chatId), {
            Messages: arrayRemove({
              id: MessageID,
              senderId: MessageSenderID,
              receiverId: MessageReceiverID,
              MessageSentTimestamp: MessageTime,
              text: "",
              document: MessageDocument,
            }),
          });
          const desertRef = ref(Chatsstorage, MessageDocument);
          deleteObject(desertRef)
        } else if (MessageImage) {
          updateDoc(doc(Chatsdb, "Chats", data.chatId), {
            Messages: arrayRemove({
              id: MessageID,
              senderId: MessageSenderID,
              receiverId: MessageReceiverID,
              MessageSentTimestamp: MessageTime,
              text: "",
              img: MessageImage,
            }),
          });
          const desertRef = ref(Chatsstorage, MessageImage);
          deleteObject(desertRef)
        }
      }
    });
  }

  // const Emoji1 = MessageID + "_1"
  // const Emoji2 = MessageID + "_2"

  // async function onClick(emojiData: EmojiClickData, event: MouseEvent) {
  // await updateDoc(doc(Chatsdb, "MessageReaction", data.chatId), {
  //   [MessageID + `.${currentUser.displayName}`]: {
  //     ReactedMessageID: MessageID,
  //     EmojiCode: emojiData.unified,
  //   }
  // });

  //   await setDoc(doc(Chatsdb, "MessageReaction", MessageID), {
  //     ReactedMessageID: MessageID,
  //     EmojiCode: emojiData.unified,
  //     NameOfUserReactedOnMessage: currentUser.displayName,
  //   });

  // await setDoc(doc(Chatsdb, "MessageReaction", MessageID), {
  //   [currentUser.displayName]: {
  //     ReactedMessageID: MessageID,
  //     EmojiCode: emojiData.unified,
  //   }
  // });

  // const ReactionLastMessage = "Reacted On The Message"
  // await updateDoc(doc(Chatsdb, "MessageData", data.user.uid), {
  //   [data.chatId + ".seen"]: false,
  //   [data.chatId + ".UnreadMessageCount"]: increment(1),
  // });
  // await updateDoc(doc(Chatsdb, "UserChats", currentUser.uid), {
  //   [data.chatId + ".lastMessage"]: {
  //     text: ReactionLastMessage,
  //     id: currentUser.uid,
  //     user: currentUser.displayName,
  //     seen: "Unseen",
  //     time: combinedCurrentDateTime,
  //     UnreadMsgCount: 0
  //   },
  //   [data.chatId + ".date"]: serverTimestamp(),
  // });
  // await updateDoc(doc(Chatsdb, "UserChats", data.user.uid), {
  //   [data.chatId + ".lastMessage"]: {
  //     text: ReactionLastMessage,
  //     id: currentUser.uid,
  //     user: currentUser.displayName,
  //     seen: "Unseen",
  //     time: combinedCurrentDateTime,
  //     UnreadMsgCount: 0
  //   },
  //   [data.chatId + ".date"]: serverTimestamp(),
  // });
  // }

  async function onClick(emojiData, event) {
    swal.close()
    // const CurrentUserUIDMessageID = currentUser.uid + MessageID
    // const res = await getDoc(doc(MessageReactiondb, "MessageReaction", CurrentUserUIDMessageID));
    // if (!res.exists()) {
    const UniqueIDForReaction = uuid()
    await setDoc(doc(MessageReactiondb, "MessageReaction", UniqueIDForReaction), {
      ReactedMessageID: MessageID,
      EmojiCode: emojiData.unified,
      NameOfUserReactedOnMessage: currentUser.displayName,
      ChatId: data.chatId,
    });
    // } else {
    //   await updateDoc(doc(MessageReactiondb, "MessageReaction", CurrentUserUIDMessageID), {
    //     ReactedMessageID: MessageID,
    //     Emoji: emojiData.unified,
    //     NameOfUserReactedOnMessage: currentUser.displayName,
    //   });
    // }
  }

  const Reaction = async () => {
    swal(
      <EmojiPicker theme="dark" emojiStyle="native" lazyLoadEmojis="true" onEmojiClick={onClick} autoFocusSearch={false} />, {
      button: "CLOSE",
    }
    )
  }

  const HandleMessageForwarding = async () => {
    if (MessageText) {
      forwardingContent("Forward Text")
      forward(MessageText)
    } else if (MessageAudio) {
      forwardingContent("Forward Audio")
      forward(MessageAudio)
    } else if (MessageVideo) {
      forwardingContent("Forward Video")
      forward(MessageVideo)
    } else if (MessageDocument) {
      forwardingContent("Forward Document")
      forward(MessageDocument)
    } else if (MessageImage) {
      forwardingContent("Forward Image")
      forward(MessageImage)
    }
  }

  // useEffect(() => {
  // if (component === "Tools") {
  //   document.getElementById("ToolsContainer").classList.add("Transition")
  // } else {
  //   document.getElementById("ToolsContainer").classList.remove("Transition")
  // }
  // }, [])

  const Copied = () => toast.success('Text Copied!', {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const CopyToMemory = () => {
    navigator.clipboard.writeText(MessageText).then(() => {
      Copied()
    }).catch(() => {
      swal("Something Went Wrong");
    });
  }

  const [inputText, setInputText] = useState("");

  if (inputText === "") {
    // TypingStatus(false)
  }

  const HandleTextChange = (e) => {
    // if (letters.test(e.target.value) !== true && e.target.value !== "" && e.code !== "Enter") {
    // TypingStatus(true)
    // } else {
    // TypingStatus(false)
    // }
    if (e.target.value[0] === ' ') {
      e.target.value = e.target.value.trimStart()
    }
    if (inputText !== "") {
      setText("")
      setImg(null);
      setMusic(null);
      setVideo(null);
      setDocument(null);
      setLoading(false);
    }
    setText(e.target.value);
    setInputText(e.target.value);
  }

  const ChangeHideOptions = (val) => {
    HideOptions(val)
  }

  useEffect(() => {
    const PerformAction = () => {
      if (ActionToBePerformed === "REACT") {
        Reaction()
        ChangeActionToBePerformedToNull("")
      } else if (ActionToBePerformed === "FORWARD") {
        HandleMessageForwarding()
        ChangeActionToBePerformedToNull("")
      } else if (ActionToBePerformed === "OPENDOC") {
        openDocument()
        ChangeActionToBePerformedToNull("")
      } else if (ActionToBePerformed === "OPENIMG") {
        openImage()
        ChangeActionToBePerformedToNull("")
      } else if (ActionToBePerformed === "OPENAUD") {
        openAudio()
        ChangeActionToBePerformedToNull("")
      } else if (ActionToBePerformed === "OPENVID") {
        openVideo()
        ChangeActionToBePerformedToNull("")
      } else if (ActionToBePerformed === "COPY") {
        CopyToMemory()
        ChangeActionToBePerformedToNull("")
      } else if (ActionToBePerformed === "DELETE") {
        UnsendMessage()
        ChangeActionToBePerformedToNull("")
      }
    }
    PerformAction()
  }, [ActionToBePerformed])

  if (err) {
    swal(err)
  }

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <>
      {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
      <footer className="w-full flex items-center justify-between bg-white dark:bg-slate-600 p-2 rounded-2xl absolute left-0 bottom-0 gap-2 md:gap-4 lg:-bottom-4 z-100" onClick={() => ChangeHideOptions(false)}>
        <div className="bg-gray-100 dark:bg-slate-800 w-full flex items-center rounded-lg relative px-1 md:px-2">
          <ToastContainer
            position="top-center"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          {/* {component === "Tools" ?
            <>
              <div className="bg-gray-100 dark:bg-slate-800 flex flex-row text-sm p-2 w-full text-center justify-center align-center">
                <ul className="flex flex-row items-center space-x-8 text-white font-extrabold" id="MsgContainer">
                  <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={Reaction}><BsEmojiSmile /></li>
                  <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={HandleMessageForwarding}><i className="fi fi-rr-redo dark:text-white text-slate-800 font-extrabold flex transition duration-300"></i></li>
                  {MessageDocument ? <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={openDocument}><i class="fi fi-rr-arrow-up-right-from-square dark:text-white text-slate-800 font-extrabold flex transition duration-300"></i></li> : null}
                  {MessageImage ? <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={openImage}><i class="fi fi-rr-arrow-up-right-from-square dark:text-white text-slate-800 font-extrabold flex transition duration-300"></i></li> : null}
                  {MessageAudio ? <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={openAudio}><i class="fi fi-rr-arrow-up-right-from-square dark:text-white text-slate-800 font-extrabold flex transition duration-300"></i></li> : null}
                  {MessageVideo ? <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={openVideo}><i class="fi fi-rr-arrow-up-right-from-square dark:text-white text-slate-800 font-extrabold flex transition duration-300"></i></li> : null}
                  {MessageText === "" ? null : <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={CopyToMemory}><i class="fi fi-rr-duplicate"></i></li>}
                  {MessageSenderID === currentUser.uid && <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={UnsendMessage}><i className="fi fi-rr-trash dark:text-white text-slate-800 font-extrabold flex transition duration-300"></i></li>}
                  <li className="text-slate-800 font-extrabold dark:text-white cursor-pointer" onClick={update}><i className="fi fi-rr-cross dark:text-white text-slate-800 font-extrabold flex transition duration-300"></i></li>
                </ul>
              </div>
            </>
            : */}
          {/* <input className="text-sm p-2 bg-gray-100 dark:bg-slate-800 dark:text-white w-full outline-0 font-extrabold" type="text" name="message" id="message" ref={InputRef} placeholder="Type A Message...." onChange={(e) => HandleTextChange(e)}
              value={inputText} onKeyPress={handleKey} /> */}
          <ThemeProvider theme={theme}>
            <div className="w-full">
              <TextField
                id="outlined-multiline-flexible"
                multiline
                maxRows={1}
                className="w-full outline-none border-none"
                label="Type A Message..."
                value={inputText} onKeyPress={handleKey}
                inputRef={InputRef} onChange={(e) => HandleTextChange(e)}
              />
            </div>
          </ThemeProvider>
          {/* <TextField
              id="filled-multiline-flexible"
              ref={InputRef}
              placeholder="Type A Message...."
              onChange={(e) => HandleTextChange(e)}
              multiline
              maxRows={2}
              variant="filled"
              className="w-full placeholder-white"
              value={inputText}
              onKeyPress={handleKey}
            /> */}
          {inputText === "" || letters.test(inputText) === true ? null :
            <span className="bg-violet-500 mr-2 cursor-pointer h-6 aspect-square flex items-center justify-center rounded-full transition duration-300 group hover:bg-violet-600" onClick={HandleCancel}>
              <i className="fi fi-rr-cross text-white flex transition duration-300"></i>
            </span>
          }
          <div className="bg-white dark:bg-slate-600 flex gap-2 rounded-full w-max">
            {/* {text !== "" ? <MdCancel /> : null} */}
            <div id="file-uploaded-data" className="pl-4 items-center justify-center relative group hidden">
              <picture id="file-tumbnail"
                className="hidden h-40 aspect-square absolute p-2 bg-white rounded-lg shadow-3xl -top-44 group-hover:block">
              </picture>
            </div>
            {inputText === "" || letters.test(inputText) === true
              ?
              <>
                <input type="file" style={{ display: 'none' }} id="file" onChange={(e) => handleImageChange(e)} />
                <label className="cursor-pointer h-7 aspect-square flex items-center justify-center rounded-full transition duration-300 group" htmlFor="file">
                  <img src={Attach} alt="" />
                </label>
              </>
              :
              null
            }
          </div>
          {/* </> */}
          {/* } */}
        </div>
        {/* {text === "" ? null :
          <span className="bg-violet-500 cursor-pointer h-8 aspect-square flex items-center justify-center rounded-lg transition duration-300 group hover:bg-violet-600" onClick={HandleSend}>
            <i className="fi fi-rr-paper-plane text-white flex transition duration-300"></i>
          </span>
        } */}
        {inputText === "" || letters.test(inputText) === true ? null :
          <span className="bg-violet-500 cursor-pointer h-8 aspect-square flex items-center justify-center rounded-lg transition duration-300 group hover:bg-violet-600" onClick={manageSendData}>
            <i className="fi fi-rr-paper-plane text-white flex transition duration-300"></i>
          </span>
        }
      </footer >

    </>
  )
}

export default Input