/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import {
  // collection,
  // query,
  // where,
  // getCountFromServer,
  // getDocs,
  onSnapshot,
  // updateDoc,
  doc,
  updateDoc,
  query,
  collection,
  where
} from "firebase/firestore";
import { db, Chatsdb, MessageDatadb, TypingStatusdb, MessageReactiondb } from "../firebase";
import Messages from './Messages';
// import Typing from './Typing';
import Input from './Input';
import { ChatContext } from "../context/ChatContext";
// import { GroupContext } from "../context/GroupContext";
// import {Link} from 'react-router-dom';
import moment from 'moment';
// import { useRef } from "react";
// import { useSelector, useDispatch } from "react-redux";
import LoadingBar from 'react-top-loading-bar';
import { AuthContext } from "../context/AuthContext";
import * as LoadingSpinner from "react-spinners-kit";
import Forward from './Forward'
import { v4 as uuid } from "uuid";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { RWebShare } from "react-web-share";
import swal from "@sweetalert/with-react";

const Chat = (props) => {
  const { data } = useContext(ChatContext);
  // const { groupData } = useContext(GroupContext);
  const [user, setUser] = useState(null);
  // const [chatWindow, setChatWindow] = useState(false);
  // const [lastActive, setLastActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progresspercent, setProgressPercent] = useState();
  const { currentUser } = useContext(AuthContext);

  const [toolsInput, setToolsInput] = useState("Input")
  const [sendMsgId, setSendMsgId] = useState(null)
  const [sendMsgReceiverID, setSendMsgReceiverID] = useState(null)
  const [sendMsgText, setSendMsgText] = useState(null)
  const [sendMsgSenderID, setSendMsgSenderID] = useState(null)
  const [sendMsgDoc, setSendMsgDoc] = useState(null)
  const [sendMsgImg, setSendMsgImg] = useState(null)
  const [sendMsgAud, setSendMsgAud] = useState(null)
  const [sendMsgVid, setSendMsgVid] = useState(null)
  const [sendMsgTime, setSendMsgTime] = useState(null)
  // const [emptyMsg, setEmptyMsg] = useState(false)
  const [messages, setMessages] = useState([])
  const [reactions, setReactions] = useState([])
  const [forwardMsg, setForwardMsg] = useState(false)
  const [fwdmsgcontent, setFwdmsgcontent] = useState(null)
  const [actionName, setActionName] = useState("")
  const [showOptions, setShowOptions] = useState(false)
  const [fwdmsgvalue, setFwdmsgvalue] = useState(null)
  const [functionChange, setFunctionChange] = useState(null)
  const [currentChatID, setCurrentChatID] = useState("")
  const [typingStatus, setTypingStatus] = useState(false)

  // const InputTools = createContext()

  // console.log(groupData.groupName)

  if (user === null) {
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • Home"
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • Home"
    }
  } else {
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • " + data.user?.displayName;
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • " + data.user?.displayName;
    }
  }

  useEffect(() => {
    const handleSearch = async () => {
      const unsub = onSnapshot(doc(db, "Users", data.user?.uid), (doc) => {
        setUser(doc.data());
      });

      return () => {
        unsub();
      };
    };
    handleSearch()
    setShowOptions(false)
  }, [data.user?.uid])

  const [otherUserMsgData, setOtherUserMsgData] = useState(null)
  const [otherUserMsgUnSeen, setOtherUserMsgUnSeen] = useState(null)
  const [otherUserMsgChatWindow, setOtherUserMsgChatWindow] = useState(null)

  useEffect(() => {
    const GetMessageData = async () => {
      const unsub = onSnapshot(doc(MessageDatadb, "MessageData", data.user?.uid), (doc) => {
        setOtherUserMsgData(doc.data());
        setOtherUserMsgUnSeen(doc.data().MessageUnSeen)
        setOtherUserMsgChatWindow(doc.data().InChatWindow)
      });

      return () => {
        unsub();
      };
    }
    GetMessageData()
  }, [data.user?.uid])

  // useEffect(() => {
  //   const GetTypingStatus = async () => {
  //     const unsub = onSnapshot(doc(TypingStatusdb, "TypingStatus", data.user?.uid), (doc) => {
  //       setCurrentChatID(doc.data().CurrentChatID)
  //       setTypingStatus(doc.data().TypingStatus)
  //     });

  //     return () => {
  //       unsub();
  //     };
  //   }
  //   GetTypingStatus()
  // }, [data.user?.uid])

  // useEffect(() => {
  //   console.log(typingStatus)
  // }, [typingStatus])

  // console.log(props.CurrentUserTypingStatus.TypingStatus)
  // const ChangeTypingStatus = async (val) => {
  //   if (props.CurrentUserTypingStatus.TypingStatus !== val) {
  //     await updateDoc(doc(TypingStatusdb, "TypingStatus", currentUser.uid), {
  //       TypingStatus: val,
  //     })
  //   }
  // }

  const RemoveInChatWindow = async () => {
    await updateDoc(doc(MessageDatadb, "MessageData", currentUser.uid), {
      InChatWindow: "",
    });
    // await updateDoc(doc(TypingStatusdb, "TypingStatus", currentUser.uid), {
    //   CurrentChatID: "",
    // })
  }

  // var current = new Date();
  // var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
  // var CurrentDate = current.toLocaleDateString();
  // var CombinedCurrentDateTime = CurrentDate + " " + CurrentTime

  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const startTime = moment(user?.LogoutTime, 'DD/MM/YYYY hh:mm a');
  const endTime = moment(combinedCurrentDateTime, 'DD/MM/YYYY hh:mm a');
  const duration = moment.duration(endTime.diff(startTime));
  const hours = parseInt(duration.asHours());
  const days = parseInt(duration.asDays());
  const minutes = parseInt(duration.asMinutes()) % 60;
  const lastActivem = minutes + "m"
  const lastActiveh = hours + "h"
  const lastActived = days + "d"

  const UpdateComponent = () => {
    setToolsInput("Input")
  }

  const HomeScreen = () => {
    props.rcw()
    RemoveInChatWindow()
    setForwardMsg(false)
    setToolsInput("Input")
    setShowOptions(false)
  }

  if (props.scw === false) {
    if (props.SendMsgCount === 0) {
      document.title = "Drake Chat • Home"
    } else {
      document.title = "(" + props.SendMsgCount + ") Drake Chat • Home"
    }
  }

  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const [chatLoading, setChatLoading] = useState(false);

  useEffect(() => {
    setChatLoading(true);
    const unSub = onSnapshot(doc(Chatsdb, "Chats", data.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().Messages);
      // doc.exists() && setReaction(doc.data().reaction);
      const SetSleep = async () => {
        await sleep(100)
        setChatLoading(false);
      };
      SetSleep()
    });

    return () => {
      unSub();
    };
  }, [data.chatId]);

  const ReactionArray = [];

  useEffect(() => {
    const GettingReactions = async () => {
      const q = query(collection(MessageReactiondb, "MessageReaction"), where("ChatId", "==", data.chatId));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          setReactions("NO REACTIONS");
          // console.log("NO DATA");
        } else {
          ReactionArray.length = 0
          querySnapshot.forEach((doc) => {
            ReactionArray.push(doc.data());
            setReactions(ReactionArray);
            console.log(ReactionArray)
          });
        }
      });
    }
    data.chatId && GettingReactions()
  }, [data.chatId]);

  useEffect(() => {
    setToolsInput("Input")
  }, [data.chatId]);

  useEffect(() => {
    if (!props.ChangeFwdCompState) {
      setForwardMsg(false);
    }
  }, [data.chatId]);

  // useEffect(() => {

  // }, [typingStatus])

  const ForwardMsgTo = (fwdmsg) => {
    setForwardMsg(true)
    setFwdmsgvalue(fwdmsg)
  }

  const FunctionChange = (val) => {
    setFunctionChange(val)
  }

  const ForwardMsgContentTo = (fwdmsgcontent) => {
    setForwardMsg(true)
    setFwdmsgcontent(fwdmsgcontent)
  }

  const ShowOptions = (val) => {
    setShowOptions(val);
  }

  const PerformAction = (val) => {
    setShowOptions(false);
    setActionName(val);
  }

  const HideOptions = () => {
    if (showOptions === false) {
      return false
    } else {
      ShowOptions(false);
    }
  }

  const [scrollDown, setScrollDown] = useState("")

  const ScrollIntoView = () => {
    setScrollDown(uuid())
    const element = document.getElementById("MsgContainer");
    element.scrollTop = element.scrollHeight;

    document.getElementById("MsgContainer").scrollTo(0, document.getElementById("MsgContainer").scrollHeight);
  }

  // useEffect(() => {
  //   ref.current?.scrollIntoView({ behavior: "smooth" });
  // }, [messages]);

  // console.log(props.MessageSeen, props.InChatWindow)

  const ref = useRef()

  useEffect(() => {
    if (props.scw === true) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
      if (chatLoading === false) {
        ref.current?.scrollIntoView({ behavior: "smooth" });
        const j = 4
        for (let i = 0; i < j; i++) {
          ref.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [data.chatId, chatLoading])

  const [showTopBtn, setShowTopBtn] = useState(false);

  const goToTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (chatLoading === false) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatLoading])

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [])

  const [showMoreChats, setShowMoreChats] = useState("")
  const [moreChatLoading, setMoreChatLoading] = useState(false)

  const handleScroll = (event) => {
    const height = event.currentTarget.clientHeight;
    const barHeight = event.currentTarget.scrollHeight;
    const scrollTop = event.currentTarget.scrollTop;
    // console.log("height - ", height)
    // console.log("barHeight - ", barHeight)
    // console.log("scrollTop - ", scrollTop)
    if (scrollTop === 0) {
      setMoreChatLoading(true)
      setShowMoreChats(uuid())
    }
    const scroller = Math.round(((scrollTop + height) / barHeight) * 100);
    // console.log(Math.round(scroller))
    if (scroller <= 99) {
      setShowTopBtn(true)
    } else {
      setShowTopBtn(false)
    }
  };

  // if (messages) {
  //   // const msg = messages.find(o => o.text === 'Hello')
  //   messages.map(o => (
  //     o.text.toLowerCase().includes('Hello'.toLowerCase()) ?
  //       console.log(o) : null
  //   ))
  //   // console.log(msg)
  // }

  return (
    <>
      {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
      {props.scw === false ? null :
        <section className=" w-full absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl h-full left-0 top-0 p-4 overflow-hidden lg:block lg:relative lg:w-3/5 xl:w-3/4" id="chat" onClick={HideOptions}>
          <header className="flex justify-between px-2 md:px-4 lg:px-6">
            <div className="flex gap-2 items-center">
              <span className="my-auto cursor-pointer" id="close-chat" onClick={HomeScreen}>
                <i className="fi fi-rr-angle-small-left flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
              </span>
              <picture className="relative flex h-8 aspect-square cursor-pointer sm:h-12" id="open-detail">
                <img className="h-full w-full object-cover block rounded-full" src={user?.photoURL} alt="user" />
                {user?.Status === "Active Now" ? <b className="bg-lime-400 h-3.5 border-2 border-white dark:border-slate-800 shadow-lg shadow-pink-500 aspect-square block rounded-full absolute right-0 bottom-0"></b> : <b className="bg-red-400 h-3.5 border-2 border-white shadow-lg shadow-pink-500 aspect-square block rounded-full absolute right-0 bottom-0"></b>}
              </picture>
              <div className="text-sm">
                <h2 className="font-extrabold whitespace-nowrap dark:text-white">{user?.displayName}</h2>
                <p className="text-xs dark:text-slate-200">{user?.Status === "Active Now" ? "Active Now" : days === 0 ? hours === 0 ? minutes === 0 ? "Active Few Seconds Ago" : "Active " + lastActivem + " Ago" : "Active " + lastActiveh + " Ago" : "Active " + lastActived + " Ago"}</p>
              </div>
            </div>
          </header>
          <main className="bg-gray-200 dark:bg-slate-800 w-full h-[90%] mt-4 p-2 !pr-2 rounded-2xl md:p-4 lg:p-6">
            <div className="w-full h-full relative">
              {forwardMsg
                ?
                <Forward MessageUnSeen={props.MessageUnSeen} InChatWindow={props.InChatWindow} msgData={props.MessageData} CurrentChatId={currentChatID} OtherUserMsgData={otherUserMsgData} OtherUserMsgUnSeen={otherUserMsgUnSeen} OtherUserMsgChatWindow={otherUserMsgChatWindow} SendChatData={props.chatdata} messages={messages} CurrentMessageValue={fwdmsgvalue} CurrentMsgContent={fwdmsgcontent} ExitForwardMessage={() => setForwardMsg(false)} SendGroupsData={props.GroupsData} />
                :
                <>
                  {chatLoading ?
                    <>
                      <div className="w-full h-[90%] justify-center text-center items-center align-center flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden">
                        <LoadingSpinner.MetroSpinner size={60} color="#ff0058" loading={chatLoading} />
                      </div>
                    </>
                    :
                    <>
                      {messages.length === 0 ?
                        <div className="w-full h-[90%] justify-center text-center items-center align-center flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden">
                          <h1 className="text-slate-800 dark:text-white font-extrabold uppercase">No Messages Here. Be The First One To Start A Conversation.</h1>
                        </div>
                        :
                        <div id="MsgContainer" onScroll={handleScroll} className="w-full h-[85%] flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden" onClick={HideOptions}>
                          {showOptions === true ?
                            <div className="modal fade fixed items-center justify-center right-[17%] top-[35%] align-middle z-50 w-auto h-auto outline-none overflow-x-hidden overflow-y-auto rounded-2xl"
                              id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
                              <div className="modal-dialog relative w-auto pointer-events-none">
                                <div
                                  className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white dark:bg-gray-600 bg-clip-padding rounded-md outline-none text-current">
                                  <div
                                    class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                    <h4 class="text-xl font-medium leading-normal text-gray-800 dark:text-white" id="exampleModalLabel"><i class="fi fi-rr-info"></i> INFO</h4>
                                    <ul className="flex flex-col items-center justify-center text-justify text-white font-extrabold" id="MsgContainer">
                                      <li className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer">{sendMsgSenderID === currentUser.uid ? currentUser.displayName : data.user?.displayName}</li>
                                      <li className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer uppercase">{sendMsgTime}</li>
                                    </ul>
                                  </div>
                                  <div className="modal-body relative rounded-2xl flex justify-center items-center w-full">
                                    <ul className="flex flex-col items-center justify-center text-justify text-white font-extrabold w-full" id="MsgContainer">
                                      {/* <RWebShare
                                        data={{
                                          text: sendMsgText,
                                          url: sendMsgText,
                                          title: "Drake Chat",
                                        }}
                                      // onClick={() => swal("Shared Successfully!")}
                                      >
                                        <button>Share</button>
                                      </RWebShare> */}
                                      <li onClick={() => PerformAction("REACT")} id="REACT" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-grin-alt dark:text-white text-white font-extrabold flex transition duration-300"></i>REACT</li>
                                      <li onClick={() => PerformAction("FORWARD")} id="FORWARD" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-redo dark:text-white text-white font-extrabold flex transition duration-300"></i>FORWARD</li>
                                      {sendMsgDoc && <li onClick={() => PerformAction("OPENDOC")} id="OPENDOC" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                      {sendMsgImg && <li onClick={() => PerformAction("OPENIMG")} id="OPENIMG" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                      {sendMsgAud && <li onClick={() => PerformAction("OPENAUD")} id="OPENAUD" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                      {sendMsgVid && <li onClick={() => PerformAction("OPENVID")} id="OPENVID" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                      {/* {sendMsgDoc || sendMsgImg || sendMsgAud || sendMsgVid ? <li onClick={Download} id="DOWNLOAD" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-cloud-download-alt dark:text-white text-white font-extrabold flex transition duration-300"></i>DOWNLOAD</li> : null} */}
                                      {sendMsgText === "" ? null : <li onClick={() => PerformAction("COPY")} id="COPY" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-duplicate dark:text-white text-white font-extrabold flex transition duration-300"></i>COPY</li>}
                                      {sendMsgSenderID === currentUser.uid && <li onClick={() => PerformAction("DELETE")} id="DELETE" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify f justify-center items-centeri fi-rr-trash dark:text-white text-white font-extrabold flex transition duration-300"></i>UNSEND</li>}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            null
                          }
                          <Messages RenderMoreChats={showMoreChats} MoreChatLoading={moreChatLoading} ChangeMoreChatsLoading={(value) => setMoreChatLoading(value)} MessageUnSeen={props.MessageU3nSeen} InChatWindow={props.InChatWindow} msgData={props.MessageData} CurrentChatId={currentChatID} OtherUserMsgData={otherUserMsgData} OtherUserMsgUnSeen={otherUserMsgUnSeen} OtherUserMsgChatWindow={otherUserMsgChatWindow} messages={messages} onChange={(value) => setToolsInput(value)} ToolsChange={toolsInput} mid={(value) => setSendMsgId(value)} mreceid={(value) => setSendMsgReceiverID(value)} mtxt={(value) => setSendMsgText(value)} msendid={(value) => setSendMsgSenderID(value)} mdoc={(value) => setSendMsgDoc(value)} mimg={(value) => setSendMsgImg(value)} maud={(value) => setSendMsgAud(value)} mvid={(value) => setSendMsgVid(value)} mtime={(value) => setSendMsgTime(value)} UpdateFunction={(value) => FunctionChange(value)} InputText={props.SendInputText} Options={(value) => ShowOptions(value)} ShowOptionsState={showOptions} ScrollDown={scrollDown} />
                          {typingStatus &&
                            <div className="flex flex-row gap-2 items-center">
                              <picture className="relative flex h-8 aspect-square cursor-pointer sm:h-9 lg:h-10">
                                <img className="h-full w-10 object-cover block rounded-full" src={data.user.photoURL} alt="user" />
                              </picture>
                              <h1 className="items-center font-extrabold text-slate-800 dark:text-white"><LoadingSpinner.StageSpinner size={40} /></h1>
                            </div>
                          }
                          <h1 className="invisible" id="scrollelem" ref={ref}></h1>
                        </div>
                      }
                    </>
                  }
                  {/* <Input MessageUnSeen={props.MessageUnSeen} InChatWindow={props.InChatWindow} msgData={props.MessageData} CurrentChatId={currentChatID} OtherUserMsgData={otherUserMsgData} OtherUserMsgUnSeen={otherUserMsgUnSeen} OtherUserMsgChatWindow={otherUserMsgChatWindow} TypingStatus={(value) => ChangeTypingStatus(value)} component={toolsInput} update={UpdateComponent} forward={(value) => ForwardMsgTo(value)} forwardingContent={(value) => ForwardMsgContentTo(value)} MessageID={sendMsgId} MessageText={sendMsgText} MessageSenderID={sendMsgSenderID} MessageReceiverID={sendMsgReceiverID} MessageDocument={sendMsgDoc} MessageImage={sendMsgImg} MessageAudio={sendMsgAud} MessageVideo={sendMsgVid} MessageTime={sendMsgTime} ProgressState={(value) => setLoading(value)} ProgressPercentage={(value) => setProgressPercent(value)} Function={functionChange} SetInputText={(value) => props.GetInputText(value)} InputText={props.SendInputText} ActionToBePerformed={actionName} HideOptions={(value) => ShowOptions(value)} ChangeActionToBePerformedToNull={() => setActionName("")} /> */}
                  {showTopBtn && (
                    <div className="top-to-btm">
                      <FaAngleDown className="icon-position icon-style" onClick={goToTop} />
                    </div>
                  )}
                  <Input MessageUnSeen={props.MessageUnSeen} InChatWindow={props.InChatWindow} msgData={props.MessageData} CurrentChatId={currentChatID} OtherUserMsgData={otherUserMsgData} OtherUserMsgUnSeen={otherUserMsgUnSeen} OtherUserMsgChatWindow={otherUserMsgChatWindow} component={toolsInput} update={UpdateComponent} forward={(value) => ForwardMsgTo(value)} forwardingContent={(value) => ForwardMsgContentTo(value)} MessageID={sendMsgId} MessageText={sendMsgText} MessageSenderID={sendMsgSenderID} MessageReceiverID={sendMsgReceiverID} MessageDocument={sendMsgDoc} MessageImage={sendMsgImg} MessageAudio={sendMsgAud} MessageVideo={sendMsgVid} MessageTime={sendMsgTime} ProgressState={(value) => setLoading(value)} ProgressPercentage={(value) => setProgressPercent(value)} Function={functionChange} SetInputText={(value) => props.GetInputText(value)} InputText={props.SendInputText} ActionToBePerformed={actionName} HideOptions={(value) => ShowOptions(value)} ChangeActionToBePerformedToNull={() => setActionName("")} ScrollIntoView={ScrollIntoView} />
                  {/* <Input MessageUnSeen={props.MessageUnSeen} InChatWindow={props.InChatWindow} msgData={props.MessageData} CurrentChatId={currentChatID} OtherUserMsgData={otherUserMsgData} OtherUserMsgUnSeen={otherUserMsgUnSeen} OtherUserMsgChatWindow={otherUserMsgChatWindow} component={toolsInput} update={UpdateComponent} forward={(value) => ForwardMsgTo(value)} forwardingContent={(value) => ForwardMsgContentTo(value)} MessageID={sendMsgId} MessageText={sendMsgText} MessageSenderID={sendMsgSenderID} MessageReceiverID={sendMsgReceiverID} MessageDocument={sendMsgDoc} MessageImage={sendMsgImg} MessageAudio={sendMsgAud} MessageVideo={sendMsgVid} MessageTime={sendMsgTime} ProgressState={(value) => setLoading(value)} ProgressPercentage={(value) => setProgressPercent(value)} Function={functionChange} SetInputText={(value) => props.GetInputText(value)} InputText={props.SendInputText} ActionToBePerformed={actionName} HideOptions={(value) => ShowOptions(value)} ChangeActionToBePerformedToNull={() => setActionName("")} /> */}
                </>
              }
            </div>
          </main>
        </section>
      }
    </>
  )
}

export default Chat